import React from 'react'
import styles from './TimedAccessProgress.module.scss'
import { combineClasses } from '@thesoulfresh/utils'
import { env } from '~/service'

interface TimedAccessProgressProps extends React.SVGProps<SVGSVGElement> {
  progress?: number
  radius?: number
  strokeWidth?: number
  /**
   * The text used to describe what this progress represents. It will be used by
   * screen readers to describe the image and defaults to "Time Remaining".
   */
  title?: string
  /**
   * Allows you to skip the intro animation.
   */
  disableAnimations?: boolean
}

/**
 * Provides a circle showing the progress within a time range.
 * If you pass the `progress` prop, any progress value between 0-1 will
 * partially fill the circular progress. If the progress is <= 0 or >= 1, then a
 * "No" icon will be shown. Changing the progress will animate the stroke but this
 * component does not provide a timing mechanism. It is up to the parent
 * component to update the progress at regular intervals.
 */
export const TimedAccessProgress = React.memo(
  ({
    progress: progressProp = 0,
    radius: radiusProp = 50,
    strokeWidth = 10,
    style,
    className,
    title = 'Time Remaining',
    disableAnimations = env.test,
    ...props
  }: TimedAccessProgressProps) => {
    const [init, setInit] = React.useState(disableAnimations)

    // Use a tiny progress value until the entrance animation has completed.
    const progress = init ? Math.max(Math.min(progressProp, 1), 0) : 0.0000001
    const nope = progress <= 0 || progress >= 1

    const radius = radiusProp - strokeWidth
    const width = radiusProp * 2
    const center = width / 2
    const circumference = React.useMemo(() => 2 * Math.PI * radius, [radius])

    const x1 = center
    const y1 = strokeWidth
    const x2 = center
    const y2 = width - strokeWidth
    const lineLength = width - strokeWidth * 2

    React.useEffect(() => {
      if (!init) {
        const timeout = setTimeout(() => {
          setInit(true)
        }, 300)
        return () => {
          clearTimeout(timeout)
        }
      }
    }, [init])

    return (
      <svg
        role="progressbar"
        aria-valuemin={0}
        aria-valuemax={1}
        aria-valuenow={progress}
        data-testid="TimedAccessProgress"
        className={combineClasses(
          styles.TimedAccessProgress,
          nope && styles.complete,
          className
        )}
        viewBox={`0 0 ${width} ${width}`}
        width="100%"
        height="100%"
        {...props}
        style={{
          ...style,
          transform: `rotate(${nope ? '45deg' : '0deg'})`,
        }}
      >
        <title>{title}</title>
        <circle
          data-testid="TimedAccessProgress.background"
          className={styles.background}
          cx={center}
          cy={center}
          r={radius}
          strokeWidth={strokeWidth}
          strokeDasharray={circumference}
        />
        <circle
          data-testid="TimedAccessProgress.foreground"
          className={styles.foreground}
          cx={center}
          cy={center}
          r={radius}
          strokeWidth={strokeWidth}
          strokeDasharray={circumference}
          strokeDashoffset={nope ? 0 : circumference * (1 - progress)}
        />
        <line
          data-testid="TimedAccessProgress.cross"
          className={styles.line}
          x1={x1}
          y1={y1}
          x2={x2}
          y2={y2}
          strokeDasharray={lineLength}
          strokeDashoffset={nope ? 0 : lineLength}
          strokeWidth={strokeWidth}
        />
      </svg>
    )
  }
)
