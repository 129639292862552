import React from 'react'
import { useMedia } from 'react-use'

import styles from './Tour.module.scss'
import { combineClasses } from '~/util'
import { ExpandingMap } from '../map'
import { TourSteps } from './tour-steps'
import { PricingDisclosure } from '../pricing-disclosure'
import { DESTINATION_TYPES } from '~/store'
import { type TourBuildings, type Floor, type ID } from '~/service'
import { NotFound } from '../not-found'

const wideBreakpoint = Number(styles.wideBreakpoint.replace('px', '')) || 750

interface EmptyTourProps {}

/**
 * `<EmptyTour>` is displayed when there are no steps on the tour.
 */
export function EmptyTour({ ...rest }: EmptyTourProps) {
  return (
    <div data-testid="EmptyTour" className={styles.EmptyTour} {...rest}>
      <NotFound title="This Tour Is Empty" />
    </div>
  )
}

export interface TourProps extends React.HTMLAttributes<HTMLDivElement> {
  /**
   * Get the URL to an amenity page.
   */
  getAmenityURL: (id: ID) => string
  /**
   * Get the URL to a unit page.
   */
  getUnitURL: (id: ID) => string
  /**
   * Whether to show unit prices on the unit cards in the tour steps.
   */
  showUnitPrices?: boolean
  /**
   * The list of buildings on the tour.
   */
  maps?: Floor[]
  /**
   * The list of floor maps to show in the expanding map.
   */
  stops?: TourBuildings
  /**
   * Additional content to show above/below the map (depending on the device
   * width).
   */
  children?: React.ReactNode
  /**
   * Whether to use the Engrain mapping technology or our own.
   */
  useEngrainMaps?: boolean
  engrainMapId?: string
  /**
   * A callback for when the map has loaded the first map image.
   */
  // TODO Rename onMapReady
  mapReady?: () => void
  /**
   * Whether or not to show prices on model units?
   */
  isShowPriceOnModel?: boolean
  /**
   * Whether animations should be run.
   */
  animated?: boolean
}

/**
 * `Tour` component displays a map of the community with units and amenities
 * that the user would like to visit.
 */
export function Tour({
  getAmenityURL,
  getUnitURL,
  showUnitPrices,
  maps,
  stops,
  children,
  useEngrainMaps,
  engrainMapId,
  mapReady,
  animated,
  isShowPriceOnModel,
  className,
  ...rest
}: TourProps) {
  const isWide = useMedia(`(min-width: ${wideBreakpoint})`)

  if (!maps?.length && !stops?.markers?.length) return <EmptyTour />

  const hasUnits = !!stops?.markers?.find(
    (c: { type: string }) => c.type === DESTINATION_TYPES.UNIT
  )

  return (
    <div
      className={combineClasses(styles.Tour, className)}
      data-testid="Tour"
      {...rest}
    >
      {stops && children && isWide && (
        // TODO We could probably move this around using CSS grid or flex box
        // `order`
        <div data-testid="topHeader" className={styles.topHeader}>
          {children}
        </div>
      )}
      {!!maps?.length && (
        <ExpandingMap
          className={combineClasses(styles.ExpandingMap, 'full-width')}
          maps={maps}
          useEngrain={useEngrainMaps}
          engrainMapId={engrainMapId}
          onMapReady={mapReady}
          getUnitURL={getUnitURL}
          getAmenityURL={getAmenityURL}
          animated={animated}
        />
      )}
      {stops && (
        // This has to be full width for the TourSteps sticky
        // headers to fully cover the cards.
        <div className={combineClasses(styles.content, 'full-width')}>
          {children && !isWide && (
            <div data-testid="bottomHeader" className={styles.bottomHeader}>
              {children}
            </div>
          )}
          <TourSteps
            stops={stops}
            showUnitPrices={showUnitPrices}
            getUnitURL={getUnitURL}
            getAmenityURL={getAmenityURL}
            stickyStepHeaderClassName={styles.stickyStepHeader}
            isShowPriceOnModel={isShowPriceOnModel}
          />
          {hasUnits && (
            <PricingDisclosure className={styles.PricingDisclosure} />
          )}
        </div>
      )}
    </div>
  )
}
