// TODO Move this out of service
import { DateLike } from '~/service'

const SECOND = 1000
const MINUTE = SECOND * 60
const HOUR = MINUTE * 60

/**
 * Determins whether the date being compared is within 1hr of now.
 */
export const isTimeWithinTheHour = (appointmentTime: DateLike) => {
  const difference = new Date(appointmentTime).getTime() - new Date().getTime()
  return difference <= HOUR && difference >= HOUR * -1
}

export function minToMilli(min: number) {
  return min * MINUTE
}

export function milliToSec(milli: number) {
  return milli / SECOND
}

export function milliToMin(milli: number) {
  return milli / MINUTE
}

export function milliToHours(milli: number) {
  return milli / HOUR
}

export function formatHours(milli: number) {
  // TODO Use duration faling back to this
  const hours = Math.floor(milliToHours(milli))
  const minutes = Math.floor(milliToMin(milli % HOUR))
  return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`
}

export function formatSeconds(milli: number) {
  return String(Math.floor(milliToSec(milli))).padStart(2, '0')
}
