import { combineClasses } from '~/util'
import { ExpandableMapSwitcher, ExpandableMapSwitcherProps } from '../switcher'
import { MapLoader } from './MapLoader'

import styles from './ExpandingMap.module.scss'
import { Floor, ID, URL } from '~/service'

export interface ExpandingMapProps
  extends Omit<ExpandableMapSwitcherProps, 'children'> {
  /**
   * The list of map objects to show.
   */
  maps: Floor[]
  /**
   * Whether or not to use the Engrain map integration or the PowerPro
   * maps.
   */
  useEngrain?: boolean
  /**
   * The id of the Engrain map to load if using Engrain maps.
   */
  engrainMapId?: string
  /**
   * A callback that will be called once the first map has fully loaded.
   */
  onMapReady?: (e: any) => void
  /**
   * A callback to get the URL of a unit marker.
   */
  getUnitURL?: (unitId: ID) => URL
  /**
   * A callback to get the URL of an amenity marker.
   */
  getAmenityURL?: (amenityId: ID) => URL
  /**
   * Whether or not to enable animations.
   */
  animated?: boolean
}

/**
 * An expandable map switcher that will also dynamically load
 * the mapping technology used by this community.
 */
export function ExpandingMap({
  maps,
  useEngrain,
  engrainMapId,
  onMapReady,
  getUnitURL,
  getAmenityURL,
  animated,
  className,
  ...rest
}: ExpandingMapProps) {
  return (
    <ExpandableMapSwitcher
      data-testid="ExpandingMap"
      className={combineClasses(styles.ExpandableMapSwitcher, className)}
      maps={maps}
      {...rest}
    >
      {({
        index,
        floorPlate,
        units,
        amenities,
        leasingOffices,
        markers,
        expanded,
        firstFocusableRef,
      }) => (
        <MapLoader
          className={styles.MapLoader}
          key={useEngrain ? 0 : index}
          floorPlate={floorPlate}
          markers={markers}
          units={units}
          amenities={amenities}
          leasingOffices={leasingOffices}
          onLoad={(e: any) => {
            if (onMapReady) onMapReady(e)
          }}
          getUnitURL={getUnitURL}
          getAmenityURL={getAmenityURL}
          useEngrain={useEngrain}
          engrainMapId={engrainMapId}
          panZoomEnabled={expanded}
          firstFocusableRef={firstFocusableRef}
          animated={animated}
        />
      )}
    </ExpandableMapSwitcher>
  )
}
