import React from 'react'

import { combineClasses } from '~/util'

import styles from './Titles.module.scss'

export interface TitleProps extends React.HTMLAttributes<HTMLHeadingElement> {
  /**
   * The heading level to render (ex. h1, h2, etc). If undefined, then a default
   * is configured for each of the title sizes (ex. XLTitle = 1). If level is
   * any other falsy value, then a `<span>` is rendered instead of an `<h?>` tag.
   */
  level?: 1 | 2 | 3 | 4 | 5 | 6 | false
}

function BaseTitle({ level, ...props }: TitleProps) {
  const Tag: React.ElementType =
    typeof level === 'number' ? `h${level}` : 'span'
  return <Tag {...props} />
}

/**
 * Render an H1 (or pass a different `level`)
 */
export function XLTitle({
  children,
  level = 1,
  className,
  ...rest
}: TitleProps) {
  return (
    <BaseTitle
      className={combineClasses(styles.XLTitle, className)}
      level={level}
      {...rest}
    >
      {children}
    </BaseTitle>
  )
}

/**
 * Render an H2 (or pass a different `level`)
 */
export function Title({ children, level = 2, className, ...rest }: TitleProps) {
  return (
    <BaseTitle
      className={combineClasses(styles.Title, className)}
      level={level}
      {...rest}
    >
      {children}
    </BaseTitle>
  )
}

/**
 * Render an H3 (or pass a different `level`)
 */
export function SubTitle({
  children,
  level = 3,
  className,
  ...rest
}: TitleProps) {
  return (
    <BaseTitle
      className={combineClasses(styles.SubTitle, className)}
      level={level}
      {...rest}
    >
      {children}
    </BaseTitle>
  )
}

/**
 * Render an H4 (or pass a different `level`)
 */
export function ItemName({
  children,
  level = 4,
  className,
  ...rest
}: TitleProps) {
  return (
    <BaseTitle
      className={combineClasses(styles.ItemName, className)}
      level={level}
      {...rest}
    >
      {children}
    </BaseTitle>
  )
}

/**
 * Render an H5 (or pass a different `level`)
 */
export function SmallTitle({
  children,
  level = 5,
  className,
  ...rest
}: TitleProps) {
  return (
    <BaseTitle
      className={combineClasses(styles.SmallTitle, className)}
      level={level}
      {...rest}
    >
      {children}
    </BaseTitle>
  )
}

/**
 * Render an H6 (or pass a different `level`)
 */
export function MiniTitle({
  children,
  level = 6,
  className,
  ...rest
}: TitleProps) {
  return (
    <BaseTitle
      className={combineClasses(styles.MiniTitle, className)}
      level={level}
      {...rest}
    >
      {children}
    </BaseTitle>
  )
}
