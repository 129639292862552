import React from 'react'
import { TimedAccessProgress } from './TimedAccessProgress'
import styles from './TimedAccess.module.scss'
import { combineClasses } from '@thesoulfresh/utils'
import { formatHours, formatSeconds, minToMilli } from '~/util'

export interface TimedAccessProps extends React.HTMLAttributes<HTMLDivElement> {
  /**
   * The total duration that the user has access for in milliseconds.
   */
  length: number
  /**
   * The amount of the time remaining that the user still has access in
   * milliseconds.
   */
  remaining: number
  /**
   * The number of milliseconds after which to change to the warning color.
   */
  warningThreshold?: number
}

/**
 * `<TimedAccess>`
 */
export const TimedAccess = ({
  length,
  remaining,
  warningThreshold = minToMilli(5),
  className,
  ...rest
}: TimedAccessProps) => {
  const progress = Math.max(Math.min((length - remaining) / length, 1), 0)
  const warning = remaining > 0 && remaining < warningThreshold
  const lessThanMinute = remaining < minToMilli(1)

  return (
    <div
      data-testid="TimedAccess"
      className={combineClasses(
        styles.TimedAccess,
        className,
        warning && styles.warn,
        lessThanMinute && styles.lessThanMinute
      )}
      {...rest}
    >
      <TimedAccessProgress
        className={styles.TimedAccessProgress}
        progress={progress}
        strokeWidth={16}
      />
      {remaining > 0 && remaining <= length && (
        <div
          data-testid="TimedAccess.timeRemaining"
          className={styles.timeDisplay}
        >
          {lessThanMinute
            ? // Show the time in seconds
              formatSeconds(remaining) + 's'
            : // Show the time in HH:MM
              formatHours(remaining)}
        </div>
      )}
    </div>
  )
}
