import React from 'react'
import { useSelector } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { combineClasses } from '@thesoulfresh/utils'

import Check from '~/assets/icons/check-circle.svg?react'
import { env, useAnalyticsClient } from '~/service'
import {
  selectCommunity,
  selectGuestCard,
  selectGuestCardUuid,
  useSaveUserIdVerification,
} from '~/store'
import { findHeaderImage, formatDate } from '~/util'
import { getRoute } from '../routes'
import {
  AccordionItem,
  Action,
  ImageHeader,
  Modal,
  NotFound,
  OrderedList,
  ResponsiveAccordion,
  Steps,
  SubTitle,
  Title,
  toast,
} from '~/components'

import styles from './VerifyIdentity.module.scss'

/**
 * Asynchronously loads the Persona SDK and displays the Persona UI in a Modal.
 */
export function PersonaModal({
  show,
  onCancel,
  onPersonaReady,
  onComplete,
  onError,
  Persona,
  personaLoading,
  personaTemplateId = env.personaTemplateId,
  personaEnvironmentId = env.personaEnvironmentId,
}) {
  // Indicates that the Persona UI has finished rendering
  const [personaReady, setPersonaReady] = React.useState(false)

  const handlePersonaReady = () => {
    setPersonaReady(true)
    onPersonaReady && onPersonaReady()
  }

  return (
    <div data-testid="PersonaInline" className={styles.PersonaInline}>
      {!personaLoading && !!Persona && (
        <Modal
          testId="PersonaModal"
          label="Verify Identity"
          // Keep the modal open because the Persona UI takes some time to load
          // and this ensures the it renders as quickly as possible when the
          // user clicks the Verify button. Keeping the modal open also ensures
          // that the user can resume the flow if they cancel it.
          isOpen={true}
          setIsOpen={onCancel}
          light
          fullscreen
          className={styles.modalContentWrapper}
          overlayClassName={combineClasses(
            styles.modalOverlay,
            // Hide the content using CSS insead of mounting/unmounting the
            // Persona UI because it's slow.
            personaReady && show ? styles.modalVisible : ''
          )}
          // Override the body styles set by react-modal
          bodyOpenClassName={styles.allowBodyScroll}
          htmlOpenClassName={null}
          ariaHideApp={false}
          shouldFocusAfterRender={false}
          lockBodyScroll={personaReady && show}
        >
          <div className={styles.modalContent}>
            {personaEnvironmentId && (
              // Persona will not be loaded unless the environment id is
              // defined. In `env.test`, the environment id doesn't exist and
              // can only be passed as a prop. This ensures we never make
              // requests to Persona during testing.
              <Persona.Inquiry
                // referenceId={userId}
                templateId={personaTemplateId}
                environmentId={personaEnvironmentId}
                onReady={() => {
                  handlePersonaReady()
                }}
                onComplete={onComplete}
                onError={onError}
              />
            )}
          </div>
        </Modal>
      )}
    </div>
  )
}

const FollowUpSteps = ({ communityName }) => (
  <OrderedList>
    <li>Directions to {communityName}</li>
    <li>Parking Instructions</li>
    <li>Community Access Instructions</li>
    <li>
      A map of the facility with the apartments and amenities you will be
      touring.
    </li>
    <li>Information on pricing and community features.</li>
  </OrderedList>
)

function VerifiedMessage({ communityName, selfTourDirectionsURL }) {
  return (
    <div data-testid="VerifiedMessage" className={styles.verifiedMessage}>
      <div className={styles.verifiedTitle}>
        <Check className={styles.checkmark} />
        <h3>Thanks for verifying!</h3>
      </div>
      <div className={styles.verifiedMessageText}>
        <p>
          Be on the look out for a text message with all the details you need
          for your tour.
        </p>
        <FollowUpSteps communityName={communityName} />
      </div>
      <div className={styles.verifiedPreview}>
        <Action
          data-testid="previewButton"
          href={selfTourDirectionsURL}
          feel="button"
        >
          Preview My Tour
        </Action>
      </div>
    </div>
  )
}

function P({ className, ...props }) {
  return (
    <p className={combineClasses(styles.paragraph, className)} {...props} />
  )
}

function StartVerification({
  showPersona,
  setShowPersona,
  personaReady,
  requiresUserAgreement,
  communityName,
  saveError,
  onRetrySave,
  saving,
}) {
  return (
    <>
      <div className={styles.mainContent}>
        <p className={styles.instructions}>
          {saveError
            ? 'We ran into some trouble saving your identity. Please check your Internet connection and try again.'
            : 'Please verify your identity in order to view your tour details.'}
        </p>
        <div className={styles.verifyContainer}>
          {!saveError && (
            <Action
              data-testid="verifyButton"
              button
              onClick={() => setShowPersona(true)}
              loading={(showPersona && !personaReady) || saving}
              className={styles.verifyButton}
              category="user-id-verification"
              action="verification-start"
              label="start"
            >
              Verify Identity
            </Action>
          )}
          {saveError && (
            <Action
              data-testid="retryButton"
              button
              display="error"
              onClick={onRetrySave}
              loading={saving}
              className={styles.verifyButton}
              category="user-id-verification"
              action="verification-start"
              label="restart"
            >
              Retry
            </Action>
          )}
        </div>
      </div>
      <div className={styles.secondaryContent}>
        <ResponsiveAccordion className={styles.Accordion} dimension="l">
          <AccordionItem initialEntered header="Why?">
            For security purposes, we are required to know who will have access
            to the property. Think of it as the digital equivalent of leaving
            your drivers license at the Leasing Office while you tour the
            community.
          </AccordionItem>
          <AccordionItem header="Verification Steps">
            <Steps>
              <li>
                When you click “Verify Now”, you will be asked to take a photo
                of your government issued id.
              </li>
              <li>
                You will then be asked to take a photo of your face in order to
                verify it matches the photo on your id.
              </li>
              {requiresUserAgreement && (
                <li>
                  Lastly, you need to agree to the {communityName} Self Tour
                  terms.
                </li>
              )}
            </Steps>
          </AccordionItem>
          <AccordionItem header="That's It!">
            <P>
              Once that’s complete, we’ll send you the information about your
              tour including...
            </P>
            <FollowUpSteps communityName={communityName} />
          </AccordionItem>
        </ResponsiveAccordion>
      </div>
    </>
  )
}

/**
 * Show a button that opens the Persona UI.
 */
export function VerifyIdentity({
  communityName,
  userName,
  tourStart,
  headerImage,
  requiresUserAgreement,
  onComplete,
  onError,
  personaLoading,
  personaError,
  /**
   * Error indicating that the Verification process completed but we were unable
   * to store the results to our DB.
   */
  saveError,
  /**
   * A callback to retry saving of the verification results if the previous save
   * request failed.
   */
  onRetrySave,
  /**
   * We are currently attempting to save the verification results returned by
   * Persona.
   */
  saving,
  Persona,
  verified,
  selfTourDirectionsURL,
  personaTemplateId = env.personaTemplateId,
  personaEnvironmentId = env.personaEnvironmentId,
}) {
  const [showPersona, setShowPersona] = React.useState(false)
  const [personaReady, setPersonaReady] = React.useState(showPersona)

  const handleComplete = React.useCallback(
    (data) => {
      setShowPersona(false)
      onComplete && onComplete(data)
    },
    [onComplete]
  )

  return (
    <div data-testid="VerifyIdentity" className={styles.VerifyIdentity}>
      <ImageHeader
        className={styles.ImageHeader}
        imageURL={headerImage}
        imageAlt={communityName}
      >
        <Title className={styles.hello}>Hi {userName}!</Title>
        <SubTitle className={styles.subtitle}>
          We can't wait to see you{' '}
          {!!tourStart ? `on ${formatDate(tourStart)}` : ''}
        </SubTitle>
      </ImageHeader>
      {!personaError && (
        <>
          {!verified && (
            <>
              <PersonaModal
                show={showPersona}
                onCancel={() => setShowPersona(false)}
                onPersonaReady={() => setPersonaReady(true)}
                onComplete={handleComplete}
                onError={onError}
                Persona={Persona}
                personaLoading={personaLoading}
                personaError={personaError}
                personaTemplateId={personaTemplateId}
                personaEnvironmentId={personaEnvironmentId}
              />
              <StartVerification
                showPersona={showPersona}
                setShowPersona={setShowPersona}
                personaReady={personaReady}
                requiresUserAgreement={requiresUserAgreement}
                communityName={communityName}
                saveError={saveError}
                onRetrySave={onRetrySave}
                saving={saving}
              />
            </>
          )}
          {verified && (
            <VerifiedMessage
              communityName={communityName}
              selfTourDirectionsURL={selfTourDirectionsURL}
            />
          )}
        </>
      )}
      {personaError && (
        <NotFound
          data-testid="PersonaError"
          title="Unable to load your tour"
          subtitle="Please verify that you have the correct tour link."
        />
      )}
    </div>
  )
}

/**
 * Lazy load the Persona SDK
 */
function usePersonaSDK(onError) {
  const [Persona, setPersona] = React.useState()
  const [loading, setLoading] = React.useState(true)
  const [error, setError] = React.useState()

  React.useEffect(
    () => {
      // Bundle Persona in it's own bundle so users don't have to download it
      // unless they visit this page.
      import('persona')
        .then((persona) => {
          setLoading(false)
          setError(undefined)
          setPersona(persona)
        })
        .catch((error) => {
          console.error('[VerifyIdentity] Failed to load Persona SDK', error)
          setLoading(false)
          setError(error)
          setPersona(undefined)
          onError && onError(error)
        })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )
  return [loading, error, Persona]
}

function getClosestDate(dates) {
  if (!dates?.length) return undefined

  return dates
    .filter((v) => !!v)
    .map((d) => new Date(d))
    .sort((a, b) => a.getTime() - b.getTime())
    .find((d) => d >= new Date())
}

/**
 * `<VerifyIdentityConnected>` connects the VerifyIdentity
 * component with the rest of the app (ie. routing, services, store, etc.).
 * The Verify Identity page is used to verify a user's identity using their
 * driver's license or other goverment issued id.
 *
 * @param {object} props
 * @returns {ReactElement}
 */
export function VerifyIdentityConnected() {
  const uuid = useSelector(selectGuestCardUuid)
  const community = useSelector(selectCommunity)
  const guestCard = useSelector(selectGuestCard)

  const headerImage = findHeaderImage(community)
  const primaryProspect = guestCard.primaryProspect
  const closestAppointment = getClosestDate(
    guestCard.appointments?.map((a) => a.scheduled)
  )

  const [verified, setVerified] = React.useState(false)
  const [saveError, setSaveError] = React.useState()
  const [saving, setSaving] = React.useState(false)
  const saveUserVerification = useSaveUserIdVerification()
  const analytics = useAnalyticsClient()

  const onSave = async (inquiryId) => {
    try {
      setSaving(true)
      await saveUserVerification(inquiryId)
      setSaving(false)
      setVerified(true)
      analytics.trackEvent(
        'user-id-verification',
        'verification-complete',
        'success'
      )
    } catch (e) {
      toast.error(
        'We were unable to save your verification. Please check your Internet connection and try again..'
      )
      console.error('Unable to save user verification:', e)
      setSaving(false)
      setSaveError(inquiryId)
      analytics.trackEvent(
        'user-id-verification',
        'verification-error',
        'powerpro-api-error'
      )
    }
  }

  const onComplete = async (data) => {
    if (data.status === 'completed') {
      onSave(data.inquiryId)
    } else {
      console.error('Unable to complete verification:', data)
      analytics.trackEvent(
        'user-id-verification',
        'verification-error',
        'persona-error'
      )
    }
  }

  const onError = (error) => {
    // The user will see errors in the Persona interface so we don't have much
    // need to do anything here. We don't need to save the "error" state to the
    // backend because leaving the verified state will force the user to
    // re-verify on their next visit (or if they press the Retry button in this
    // session).
    //
    // Possible Persona errors:
    // https://docs.withpersona.com/docs/embedded-flow-event-handling#onerror
    console.error('Error completing inquiry', error)
    analytics.trackEvent(
      'user-id-verification',
      'verification-error',
      'persona-error'
    )
  }

  // Do lazy loading here so we can easily mock the PersonaSDK during test.
  const [loading, error, Persona] = usePersonaSDK(onError)

  // Redirect if the user is already verified
  if (primaryProspect?.idInquiry?.status === 'COMPLETED') {
    console.info('User is already verified:', primaryProspect.idInquiry)
    return <Redirect to={getRoute('SELF_TOUR_DIRECTIONS', { uuid })} />
  } else {
    return (
      <VerifyIdentity
        onComplete={onComplete}
        onError={onError}
        Persona={Persona}
        personaLoading={loading}
        personaError={error}
        saveError={!!saveError}
        onRetrySave={() => onSave(saveError)}
        saving={saving}
        communityName={community.name}
        userName={primaryProspect?.firstName}
        tourStart={closestAppointment}
        headerImage={headerImage}
        verified={verified}
        selfTourDirectionsURL={getRoute('SELF_TOUR_DIRECTIONS', { uuid })}
      />
    )
  }
}
