import React from 'react'
import type { Nilable } from 'tsdef'

import {
  combineClasses,
  formatDoorCode,
  formatTimeRange,
  minToMilli,
} from '~/util'
import { ItemName } from '../titles'

import styles from './DoorCode.module.scss'
import { Action } from '../buttons'
import LockIcon from '~/assets/icons/lock.svg?react'
import { DateLike } from '~/service'
import { TimedAccess } from '../timed-access'

export function LockCode({ code, ...rest }: { code: string }) {
  return (
    <div data-testid="LockCode" className={styles.LockCode} {...rest}>
      <LockIcon className={styles.lockIcon} />
      <ItemName className={styles.code} level={undefined}>
        {formatDoorCode(code)}
      </ItemName>
    </div>
  )
}

interface DoorCodeProps extends React.HTMLAttributes<HTMLDivElement> {
  /**
   * The code for unlocking the door. This code will be displayed to the user
   * for them to enter it into the device. This takes precedence over the
   * `openURL`.
   */
  doorCode?: Nilable<string>
  /**
   * A callback that can be used to open a door. If this is specified, then the
   * door code is replaced with a button to open the lock.
   */
  openURL?: Nilable<string>
  /**
   * The ISO date that access can begin.
   */
  accessStart?: DateLike
  /**
   * The ISO date that access ends.
   */
  accessEnd?: DateLike
  /**
   * The rate in seconds at which the component minutes remaining
   * will be refreshed.
   */
  refreshRate?: number
  /**
   * The number of minutes before `accessEnd` that
   * the component will show a warning that access is about to end.
   */
  warningTime?: number
  /**
   * Allows you to mock the time that the door code thinks it is (ie. Date.now()).
   */
  currentTime?: Date
}

/**
 * `<DoorCode>` displays the access code for an apartment and the length of time
 * that code can be used. If the code is not available yet or is in the future,
 * it is not displayed to ensure the user cannot access the unit.
 */
export const DoorCode = React.memo(
  ({
    doorCode,
    openURL,
    accessStart,
    accessEnd,
    refreshRate = 15,
    warningTime = 15,
    currentTime = new Date(),
    className,
    ...rest
  }: DoorCodeProps) => {
    const start = accessStart ? new Date(accessStart) : currentTime
    const end = accessEnd ? new Date(accessEnd) : undefined

    const started = currentTime >= start
    const ended = end ? currentTime >= end : false
    const canAccess = started && !ended

    const length = end ? end.getTime() - start.getTime() : undefined
    const remaining = end ? end.getTime() - currentTime.getTime() : undefined
    const minutesLeft = remaining ? Math.round(remaining / 60000) : undefined
    const almostUp =
      canAccess && minutesLeft != null && minutesLeft < warningTime
    const showButton = !doorCode && !!openURL

    return (
      <div
        data-testid="DoorCode"
        className={combineClasses(
          styles.DoorCode,
          className,
          canAccess && styles.accessible,
          almostUp && styles.almostUp,
          ended && styles.expired
        )}
        {...rest}
      >
        <span data-testid="DoorCode.label" className={styles.label}>
          {showButton ? 'Access' : 'Door Code'}:
        </span>
        <span data-testid="DoorCode.code" className={styles.codeValue}>
          {React.useMemo(() => {
            if (canAccess) {
              if (showButton) {
                return (
                  <Action
                    className={styles.unlockButton}
                    feel="link"
                    display="primary"
                    href={openURL}
                    blank
                    icon={<LockIcon className={styles.lockIcon} />}
                  >
                    Unlock
                  </Action>
                )
              } else if (doorCode) {
                return <LockCode code={doorCode} />
              }
            } else {
              if (ended) {
                // After access
                return (
                  <ItemName className={styles.message} level={false}>
                    Expired
                  </ItemName>
                )
              }
            }
            // Before access or no door code
            return (
              <ItemName className={styles.message} level={undefined}>
                Soon
              </ItemName>
            )
          }, [canAccess, showButton, doorCode, openURL, ended])}
        </span>
        <span
          data-testid="DoorCode.accessTime"
          className={combineClasses(styles.time, styles.label)}
        >
          {(accessStart || accessEnd) &&
            formatTimeRange(accessStart, accessEnd)}
        </span>
        <span className={styles.remaining}>
          {length != null && remaining != null && (
            <TimedAccess
              length={length}
              remaining={length === remaining ? remaining - 1 : remaining}
              warningThreshold={minToMilli(5)}
            />
          )}
        </span>
      </div>
    )
  }
)

/**
 * Renders `<DoorCode>` while keeping it in sync with the current time.
 */
export const DoorCodeTimer = React.memo((props: DoorCodeProps) => {
  const [currentTime, setCurrentTime] = React.useState(new Date())

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      setCurrentTime(new Date())
    }, 1000)
    return () => clearTimeout(timeout)
  })

  return <DoorCode currentTime={currentTime} {...props} />
})
