import React from 'react'
// import { window } from 'global';

/*
 * Scroll window to the top of the page on component load.
 */
export function useScrollToTop() {
  React.useEffect(() => {
    window.scroll(0, 0)
  }, [])
}

// Global counter used to create unique ids.
let idCount = 0

export function useId(prefix) {
  const [id] = React.useState(() => `${prefix}-${++idCount}`)
  return id
}

/**
 * Returns a wrapper around `requestAnimationFrame` that will
 * automatically clear the request if the component
 * is destroyed before the frame is rendered.
 */
export function useAnimationFrame() {
  const idRef = React.useRef()

  React.useEffect(() => {
    return () => {
      // When the component is destroyed,
      // if the timeout was created, then clear it.
      if (idRef.current) {
        cancelAnimationFrame(idRef.current)
      }
    }
  }, [])

  return (cb, ...rest) => {
    return (idRef.current = requestAnimationFrame(() => {
      idRef.current = null
      cb(...rest)
    }))
  }
}

/**
 * Returns a wrapper around `setTimeout` that will
 * automatically clear the timeout if the component
 * is destroyed before the timeout.
 */
export function useTimeout() {
  // TODO This should hold an array of timeouts.
  const idRef = React.useRef()

  React.useEffect(() => {
    return () => {
      // When the component is destroyed,
      // if the timeout was created, then clear it.
      if (idRef.current) {
        clearTimeout(idRef.current)
      }
    }
  }, [])

  return (cb, delay, ...rest) => {
    return (idRef.current = setTimeout(() => {
      idRef.current = null
      cb(...rest)
    }, delay))
  }
}

/**
 * Returns true after the specified delay in milliseconds
 * after component mount. This can be useful to delay rendering
 * of a component during initial page load. The delay value
 * is only checked on the first render and is ignored after that.
 *
 * @param {number} [delay] - The delay to wait. If null or 0
 *   is passed, then this will immediately return true.
 */
export function useDelay(delay = 3000) {
  const [rendered, setRendered] = React.useState(false)
  const wait = useTimeout()

  if (delay && !rendered) {
    wait(() => setRendered(true), delay)
    return false
  } else {
    return true
  }
}

/**
 * Determine if a component is currently mounted.
 * Use this in instances where you need to ensure that async
 * actions only happen while a component is mounted and
 * you are unable to cancel those async actions on unount.
 * It is preferred to cancel an async work started by your
 * component if possible.
 */
export function useIsMounted() {
  const state = React.useRef(true)

  React.useEffect(() => {
    // In development React will double render this effect so we need to ensure
    // the state stays true.
    state.current = true
    return () => {
      state.current = false
    }
  }, [])

  return React.useCallback(() => state.current, [])
}

/**
 * Call the given callback once the image load event
 * is triggered. This will only happen the first time
 * the image is loaded. This function returns a ref
 * that you should attach to the image in question.
 * It also returns the current loaded state in case
 * you prefer to use that along with an effect instead
 * of the callback method.
 *
 * @param {function} [onLoad] - Called once the image is loaded
 *   with a reference to the image element.
 * @returns {object} An object with ref and loaded properties.
 */
export function useImageLoad(onLoad) {
  const [loaded, setLoaded] = React.useState(false)
  const ref = React.useRef()
  const isMounted = useIsMounted()

  // Because an image may be loaded from cache before
  // this effect runs, we only add the load event handler
  // if the image isn't cached.
  React.useEffect(() => {
    if (!loaded) {
      const handleLoad = () => {
        if (isMounted()) {
          setLoaded(true)
          if (onLoad) onLoad(ref.current)
        }
      }

      const image = ref.current
      if (image) {
        if (image.complete) {
          // Fake a load event.
          handleLoad()
        } else {
          image.addEventListener('load', handleLoad)
          return () => image.removeEventListener('load', handleLoad)
        }
      } else {
        console.warn(
          '[useImageLoad] Unable to register image load event handler. Make sure you have placed the returned ref on an image element.'
        )
      }
    }
  }, [loaded, onLoad, isMounted])

  return { loaded, ref }
}

/**
 * Calls the provided callback on key down once while
 * the active flag is true. Toggling the flag resets the
 * counter allowing the callback to be tracked again.
 *
 * For efficiency, all event listeners are removed when active is false.
 *
 * @param {string} key - The event.key value
 * @param {() => void} [cb]
 * @param {boolean} active - Whether or not event listening is
 *   currently enabled.
 */
export function useKeyWhenActive(key, cb, active = true) {
  // Track the first key event after the props change.
  const pressed = React.useRef(false)

  React.useEffect(() => {
    if (active) {
      const onKeyDown = (e) => {
        if (e.key === key && !pressed.current) {
          pressed.current = true
          cb(e)
        }
      }

      const onKeyUp = (e) => {
        if (e.key === key) {
          pressed.current = false
        }
      }

      window.addEventListener('keydown', onKeyDown)
      window.addEventListener('keyup', onKeyUp)

      return () => {
        window.removeEventListener('keydown', onKeyDown)
        window.removeEventListener('keyup', onKeyUp)
      }
    } else {
      pressed.current = false
    }
  }, [active, key, cb])
}

/**
 * Trap focus within two elements. Both elements must be
 * able to receive focus for this to work so ensure they
 * are either naturally focusable (ex. buttons) or they
 * have a `tabindex` specified.
 *
 * @param {boolean} active - Allows you to turn the focus
 *   trap on and off.
 *
 * @return{[object, object]} An array of references to
 *   attach to the first and last elements in the trap.
 */
export function useFocusTrap(active = true) {
  // The first and last elements that define the focus trap.
  const first = React.useRef()
  const last = React.useRef()

  useKeyWhenActive(
    'Tab',
    (e) => {
      if (document.activeElement === last.current && !e.shiftKey) {
        e.preventDefault()
        first.current?.focus()
      } else if (document.activeElement === first.current && e.shiftKey) {
        e.preventDefault()
        last.current?.focus()
      }
    },
    active
  )

  return [first, last]
}

/**
 * A hook that generates the aria properties necessary for
 * a dropdown component.
 *
 * @param {boolean} isOpen
 * @param {string} [prefix] - A prefix to use for the id names generated
 *   for the trigger and menu elements.
 * @returns {object} - An object with `triggerProps` and `menuProps`
 *   to attach to the associated elements.
 *   `triggerProps` includes 'id', 'aria-controls' and 'aria-expanded' props.
 *   `menuProps` includes 'id' and 'aria-labelledby' props.
 */
let count = 0
export function useDropdownAria(isOpen, prefix = 'custom-popover') {
  const [id] = React.useState(() => `${prefix}-${++count}`)
  const triggerId = `${id}-trigger`
  const menuId = `${id}-menu`

  return {
    triggerProps: {
      id: triggerId,
      'aria-controls': menuId,
      'aria-expanded': isOpen ? 'true' : 'false',
    },
    menuProps: {
      id: menuId,
      'aria-labelledby': triggerId,
    },
  }
}
