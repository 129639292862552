import { combineReducers } from 'redux'
import { configureStore } from '@reduxjs/toolkit'

import selfTourReducer from './self-tour.reducer'
import * as fromSelfTour from './self-tour.selectors'
import pureChatReducer, * as fromPureChat from './pure-chat.reducer'
import { ID, Unit } from '~/service'

export const reducerConfig = {
  tour: selfTourReducer,
  chat: pureChatReducer,
}

const rootReducer = combineReducers(reducerConfig)
export type RootState = ReturnType<typeof rootReducer>
export type TourState = RootState['tour']

export function reset() {
  return {
    type: 'RESET',
    payload: null,
  }
}

export default configureStore({
  reducer: (state, action) => {
    if (action.type === 'RESET') {
      // Reset to the initial store state.
      return rootReducer(undefined, action)
    }

    return {
      ...rootReducer(state, action),
    }
  },
})

export const selectTourInitialized = (state: any) =>
  fromSelfTour.select.initialized(state.tour)

export const selectSelfTourNotices = (state: any) =>
  fromSelfTour.select.notices(state.tour)

export const selectGuestCardUuid = (state: any) =>
  fromSelfTour.select.uuid(state.tour)

export const selectUserHasSignedSelfTourAgreement = (state: any) =>
  fromSelfTour.select.userHasSignedSelfTourAgreement(state.tour)

export const selectShouldUserSignSelfTourAgreement = (state: any) =>
  fromSelfTour.select.shouldUserSignSelfTourAgreement(state.tour)

export const selectGuestCard = (state: any) =>
  fromSelfTour.select.guestCard(state.tour)

/**
 * Determine if the user has completed the check in
 * questions/steps.
 */
export const selectCheckedIn = (state: any) =>
  fromSelfTour.select.checkedIn(state.tour)

export const selectCommunity = (state: any) =>
  fromSelfTour.select.community(state.tour)

/**
 * Config values for this community.
 * @param {object} state
 */
export const selectCommunityConfig = (state: any) =>
  fromSelfTour.select.communityConfig(state.tour)

/**
 * Whether this community supports a non-sms chat tool.
 * @param {object} state
 */
export const selectCommunityHasChat = (state: any) =>
  fromSelfTour.select.communityHasChat(state.tour)

/**
 *  Gets amenities filted by guestcard amenity ids.
 */
export const selectCommunityAmenitiesFromGuestCardIds = (state: any) =>
  fromSelfTour.select.communityAmenitiesFromGuestCardIds(state.tour)

export const selectAllFeaturesForUnitsByName = (state: any) =>
  fromSelfTour.select.allFeaturesForUnitsByName(state.tour)

export const selectShowUnitPrices = (state: any) =>
  fromSelfTour.select.showUnitPrices(state.tour)

export const selectFloorPlan = (state: any, id: ID) =>
  fromSelfTour.select.floorPlan(state.tour, id)

export const selectFloorPlans = (state: any) =>
  fromSelfTour.select.floorPlans(state.tour)

// TODO Is this something that PureChat gives us or is it comming from our
// config? If our config, then remove this selector
export const selectPureChatClientId = (state: any) =>
  fromPureChat.select.id(state.chat)

export const selectIsPureChatLoaded = (state: any) =>
  fromPureChat.select.loaded(state.chat)

export const selectQuotes = (state: any) =>
  fromSelfTour.select.quotes(state.tour)

export const selectQuote = (state: any, id: ID) =>
  fromSelfTour.select.quote(state.tour, id)

export const selectQuotesForUnit = (state: any, id: ID | undefined) =>
  fromSelfTour.select.quotesForUnit(state.tour, id)

export const selectQuoteMonthlyCharges = (state: any, unit: Unit) =>
  fromSelfTour.select.quoteMonthlyCharges(state.tour, unit)

export const selectQuoteRequiredCharges = (state: any, unit: Unit) =>
  fromSelfTour.select.quoteRequiredCharges(state.tour, unit)

export const selectQuoteAdditionalCharges = (state: any, unit: Unit) =>
  fromSelfTour.select.quoteAdditionalCharges(state.tour, unit)
