import React from 'react'
// import PropTypes from 'prop-types';

import { combineClasses } from '~/util'

import styles from './PricingDisclosure.module.scss'

/**
 * @param {object} props
 * @param {string} [props.className]
 */
export function PricingDisclosure({ className, ...rest }) {
  return (
    <span
      data-testid="PricingDisclosure"
      className={combineClasses(styles.PricingDisclosure, className)}
      {...rest}
    >
      * Does not include utilities or other monthly charges.
      <br />
      ** Does not include tax where required.
    </span>
  )
}
// PricingDisclosure.propTypes = {
// };
